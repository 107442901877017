import React, { useState } from "react"
import QuoteForm from "../components/quoteForm"
import QuoteRange from "../components/quoteRange"

export default function Page() {
    const [debug] = useState(true);
    const [activeSection, setActiveSection] = useState(1);
    const [budgetStart, setBudgetStart] = useState(25000);
    const [budgetEnd, setBudgetEnd] = useState(50000);
    const [budgetMax, setBudgetMax] = useState(100000);

    return (
        <div className={`mt-16 quote-page ${debug ? 'quote-debug-on' : 'quote-debug-off'}`}>
            <div className="container quote-container flex flex-wrap flex-col items-center">
                <h1>Get a <span>Quote</span>.</h1>
                {activeSection === 1 &&
                    <div className="mb-12">
                        <p className="text-center">Answer the questions below and you will receive an <br className="hidden md:inline"/>immediate budget range for your project.</p>
                        <p className="text-center">We will review your requirements and respond with an <br className="hidden md:inline"/>official quote in 1 business day.</p>
                        <p className="text-center">Feel free to <a href="tel:+27861377704">give us a call</a> if you need clarity on any of <br className="hidden md:inline"/>these questions, or <a href="mailto:info@artificial.studio?subject=Quoting session">set up a session</a> with us to <br className="hidden md:inline"/>complete the form together.</p>
                    </div>
                }
                <QuoteForm
                    debug={debug}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                    setBudgetStart={setBudgetStart}
                    setBudgetEnd={setBudgetEnd}
                    setBudgetMax={setBudgetMax}
                    budgetStart={budgetStart}
                    budgetEnd={budgetEnd}
                    budgetMax={budgetMax}
                />
            </div>
            <QuoteRange
                debug={debug}
                budgetStart={budgetStart}
                budgetEnd={budgetEnd}
                budgetMax={budgetMax}
            />
        </div>
    )
}
